body, html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%;
  overflow: auto;
  background: url('sta.png') no-repeat center center fixed;
  background-size: cover;
  /*background-color: white;*/
  animation: none !important;
}

.twinkling-stars {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('stars.png') repeat center center fixed;
  animation: twinkle 2s infinite alternate;
  opacity: 0.5; 
  /*mix-blend-mode: multiply;*/ 
  z-index: 0;
}

@keyframes twinkle {
  from { opacity: 0.5; }
  to { opacity: 1; }
}

.content {
  position: absolute !important;
  z-index: 1 !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;
  left: 0 !important;
}

